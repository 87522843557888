import PropTypes from "prop-types"
import React from "react"


const LandingHeader = ({ siteTitle, pageInfo }) => (
  <header>
    <div
      style={{
        margin: `auto`,
        maxWidth: 1400,
        padding: `0rem`,
        fontWeight: `light`,
      }}
      className="landing-header"
    >
    </div>
  </header>
)

LandingHeader.propTypes = {
  siteTitle: PropTypes.string,
  pageTitle: PropTypes.string,
}

LandingHeader.defaultProps = {
  siteTitle: ``,
  pageTitle: ``,
}

export default LandingHeader
