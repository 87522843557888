import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import LandingLayout from "../components/landing-layout"
import Img from "gatsby-image"
import SEO from "../components/seo"
import logo from "../images/rfyg.png"



export default (props) => {
  console.log(props.data)
  const data = useStaticQuery(graphql`
  query LogoQuery {
    file(relativePath: {eq: "rfyg.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
  `)
  return (
    <LandingLayout>
      {/* <SEO title="Home" keywords={[`rise from your grave`, `carrboro`, `music festival`, `electronic music`]} />
      <div className="text-center">
      <img src={logo} className="img-fluid" alt="Responsive image" /> */}
      {/* <Img
        fluid={data.file.childImageSharp.fluid}
        alt="logo"
        style={{
          maxWidth: '700px',
          margin: 'auto'
        }}
      /> */}
      {/* </div> */}
<div className="update h4">
      <p>AN UPDATE FROM THE ORGANIZERS OF RISE FROM YOUR GRAVE</p>
      <p>In standing with the serious public health concern and the temporary closure of Nightlight and other creative spaces in the community, we have made the decision to postpone Rise From Your Grave, originally scheduled for the weekend of April 9-12.</p>
      <p>
        We are hopeful as we share that we have rescheduled the weekend for six months from its original date: Thursday October 8 - Sunday October 11, and are moving forward with the participation of all original venues: Nightlight, Northside District, The ArtsCenter, and Local 506.

        We are also elated to say that nearly all announced performers are confirmed to reschedule their sets and play with us during our new October dates!! There may be minor changes to the lineup but the vast majority of live + DJ offerings we had already slated will stay intact. We will share updates about the lineup over the coming months.
      </p>
      <p>
        We feel very grateful in this moment to be able to move forward with the work we have already done.
      </p>
      <p>
        We also understand that this reschedule predicates on the progress that is made protecting public health over the next several months. With this in mind, we are offering full refunds for those who have purchased weekend passes already and would like one. For refunds of online purchases, please contact <em>rfyg@alldayrecords.com</em>. If you purchasesd in a participating record shop, we will be in touch with you. If you are planning to attend in October and don't want to be refunded at this time, your pass will be fully honored for our new dates.
      </p>
      <p>
        Be safe in these critical times, you'll be hearing more updates from us soon.
      </p>
      <p>
        many many thanks,
      </p>
      <p>
        the RFYG team
      </p>
      </div>
    </LandingLayout>
  )
}
