/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { StaticQuery, graphql } from "gatsby"

import { Container, Row, Col} from "react-bootstrap"
import Fade from 'react-bootstrap/Fade'

import LandingHeader from "./landing-header"
import Navbar from "./navBar"
import Footer from "./footer"

const PagesLayout = ({ children, pageInfo }) => (
  <StaticQuery
    query={graphql`
      query LandingSiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Container fluid className="px-0 main">
          <Navbar pageInfo={pageInfo} />
          <Row noGutters className="justify-content-center">
            <Col>
              <LandingHeader siteTitle={data.site.siteMetadata.title} pageInfo={pageInfo} />
            </Col>
          </Row>
          <Row noGutters>
            <Col>
            <Fade
                  appear={true}
                  in={true}
                  timeout={fadeTime}
                >
              <Container className="mt-5">

                <main>{children}</main>
              </Container>
              </Fade>
            </Col>
          </Row>
        </Container>

        <Container fluid className="px-0">
          <Row noGutters>
            <Col className="footer-col">
              <Footer />
              {/* <footer>
                <span>
                  © {new Date().getFullYear()}
                </span>
              </footer> */}
            </Col>
          </Row>
        </Container>
      </>
    )}
  />
)

const fadeTime = 500

export default PagesLayout
